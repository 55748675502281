/* Base Styles */
:root {
  /* Layout */
  --layout-spacing: {
    xs: 4px;
    sm: 8px;
    md: 16px;
    lg: 24px;
    xl: 32px;
  };
  --container-width: {
    xs: 100%;
    sm: 540px;
    md: 720px;
    lg: 960px;
    xl: 1140px;
  };
  --border-radius: {
    xs: 4px;
    sm: 8px;
    md: 12px;
    lg: 16px;
    xl: 24px;
    circle: 50%;
  };

  /* Z-index layers */
  --z-index: {
    base: 1;
    dropdown: 1000;
    sticky: 1020;
    fixed: 1030;
    modal-backdrop: 1040;
    modal: 1050;
    popover: 1060;
    tooltip: 1070;
  };

  /* Transitions */
  --transition: {
    fast: 150ms;
    base: 200ms;
    slow: 300ms;
    timing: cubic-bezier(0.4, 0, 0.2, 1);
  };

  /* Shadows */
  --shadow: {
    sm: 0 1px 3px rgba(0, 0, 0, 0.12);
    md: 0 4px 6px rgba(0, 0, 0, 0.15);
    lg: 0 10px 15px rgba(0, 0, 0, 0.20);
  };

  /* Core RGB Values */
  --black-rgb: 0, 0, 0;                                /* Pure black for overlays and shadows */
  --white-rgb: 255, 255, 255;                         /* Pure white for overlays */
  --background-white-rgb: 255, 255, 255;              /* White background base */
  --text-primary-rgb: 32, 33, 36;                     /* Main text color */
  --text-secondary-rgb: 95, 99, 104;                  /* Secondary text color */
  --text-dark-rgb: 44, 44, 44;                        /* Dark text for emphasis */
  --text-light-rgb: 153, 153, 153;                    /* Light gray text */
  --text-white-rgb: 255, 255, 255;                    /* White text */
  --selection-blue-rgb: 25, 103, 210;                 /* Selection highlight color */
  --highlight-cyan-rgb: 87, 209, 240;                 /* Cyan highlight color */
  --primary-blue-rgb: 26, 115, 232;                   /* Primary blue */
  --primary-blue-hover-rgb: 21, 87, 176;              /* Hover state blue */
  --primary-blue-active-rgb: 25, 103, 210;            /* Active state blue */
  --primary-blue-light-rgb: 232, 240, 254;            /* Light blue background */
  --primary-blue-lighter-rgb: 210, 227, 252;          /* Lighter blue background */
  --border-light-rgb: 224, 224, 224;                  /* Light border */
  --border-lighter-rgb: 206, 217, 224;                /* Lighter border */
  --border-dark-rgb: 66, 66, 66;                      /* Dark border */
  --accent-purple-light-rgb: 222, 226, 251;           /* Light purple accent */
  --accent-purple-lighter-rgb: 216, 220, 250;         /* Lighter purple accent */
  --accent-blue-light-rgb: 245, 249, 255;             /* Light blue accent */
  --accent-blue-lighter-rgb: 248, 251, 255;           /* Lighter blue accent */
  --scrollbar-track-rgb: 245, 245, 245;               /* Scrollbar track */
  --scrollbar-thumb-rgb: 143, 143, 143;               /* Scrollbar thumb */
  --scrollbar-thumb-hover-rgb: 43, 104, 202;          /* Scrollbar thumb hover */
  --gradient-start-rgb: 142, 76, 168;                 /* Gradient start color */
  --gradient-middle-rgb: 211, 82, 153;                /* Gradient middle color */
  --gradient-end-rgb: 255, 149, 103;                  /* Gradient end color */
  --purple-accent-rgb: 139, 61, 255;                  /* Purple accent */
  --gradient-purple-rgb: 226, 230, 253;               /* Gradient purple */
  --purple-base-rgb: 150, 86, 180;                    /* Base purple */
  --hover-background-color-rgb: 250, 250, 250;        /* Hover background */
  --panel-background-color-rgb: 225, 230, 255;        /* Panel background */
  --background-light-rgb: 245, 245, 245;              /* Light background */
  --background-lighter-rgb: 248, 249, 254;            /* Lighter background */
  --background-header-rgb: 50, 50, 50;                /* Header background */

  /* Background Colors */
  --hover-background-color: #FAFAFA;                  /* Blueprint: LIGHT_GRAY5 | Shared hover styles */
  --panel-background-color: #e1e6ff;                  /* Custom color | Light purple/periwinkle used in panels */
  --background-light: #F5F5F5;                        /* Blueprint: LIGHT_GRAY4 | Light gray background */
  --background-lighter: #F8F9FE;                      /* Custom color | Lighter gray used in dialogs and list items */
  --background-white: #ffffff;                        /* Blueprint: WHITE | Pure white background */
  --background-header: #323232;                       /* Custom color | Dark header background */

  /* Text Colors */
  --text-primary: #202124;                            /* Blueprint: DARK_GRAY1 | Main text color */
  --text-secondary: #5F6368;                          /* Blueprint: GRAY1 | Secondary text color */
  --text-dark: #2c2c2c;                               /* Blueprint: DARK_GRAY2 | Darker text for emphasis */
  --text-light: #999999;                              /* Blueprint: GRAY3 | Light gray text */
  --text-white: #ffffff;                              /* Blueprint: WHITE | White text */

  /* Brand Colors */
  --primary-blue: #1A73E8;                            /* Blueprint: BLUE3 | Primary blue for interactive elements */
  --primary-blue-hover: #1557B0;                      /* Blueprint: BLUE2 | Darker blue for hover states */
  --primary-blue-active: #1967D2;                     /* Blueprint: BLUE1 | Active state blue */
  --primary-blue-light: #E8F0FE;                      /* Blueprint: BLUE5 | Light blue for backgrounds */
  --primary-blue-lighter: #D2E3FC;                    /* Blueprint: BLUE4 | Even lighter blue for hover states */

  /* Accent Colors */
  --accent-purple-light: #DEE2FB;                     /* Custom color | Light purple accent */
  --accent-purple-lighter: #D8DCFA;                   /* Custom color | Lighter purple accent */
  --accent-blue-light: #f5f9ff;                       /* Custom color | Light blue accent */
  --accent-blue-lighter: #f8fbff;                     /* Custom color | Lighter blue accent */

  /* Border Colors */
  --border-light: #e0e0e0;                            /* Blueprint: LIGHT_GRAY1 | Light border color */
  --border-lighter: #CED9E0;                          /* Blueprint: GRAY5 | Lighter border color */
  --border-dark: #424242;                             /* Blueprint: GRAY1 | Dark border color */

  /* Scrollbar Colors */
  --scrollbar-track: #f5f5f5;                         /* Blueprint: LIGHT_GRAY4 | Scrollbar track color */
  --scrollbar-thumb: #8f8f8f;                         /* Blueprint: GRAY2 | Scrollbar thumb color */
  --scrollbar-thumb-hover: #2b68ca;                   /* Blueprint: BLUE2 | Scrollbar thumb hover color */

  /* Gradient Colors */
  --gradient-start: #8E4CA8;                          /* Custom color | Start of gradient */
  --gradient-middle: #d35299;                         /* Custom color | Middle of gradient */
  --gradient-end: #ff9567;                            /* Custom color | End of gradient */

  /* Additional Theme Colors */
  --black: #000000;                                   /* Pure black */
  --selection-blue: #4285F4;                          /* Selection highlight */
  --highlight-cyan: #57D1F0;                          /* Cyan highlight */
  --purple-accent: #8B3DFF;                           /* Purple accent */
  --gradient-purple: #E2E6FD;                         /* Gradient purple */
  --purple-base: #9656B4;                             /* Base purple */

  /* Typography - Font Families */
  --font-primary: 'Roboto', sans-serif;               /* Main text font */
  --font-mono: 'Roboto Mono', monospace;              /* Monospace font for code/technical text */

  /* Typography - Font Weights */
  --font-weight-regular: 400;                         /* Regular text weight */
  --font-weight-medium: 500;                          /* Medium text weight for semi-emphasis */
  --font-weight-semibold: 600;                        /* Semi-bold text weight for stronger emphasis */
  --font-weight-bold: 700;                            /* Bold text weight for maximum emphasis */

  /* Typography - Font Sizes */
  --font-size-xs: 0.75rem;                            /* 12px - Extra small text */
  --font-size-sm: 0.8rem;                             /* 12.8px - Small text */
  --font-size-base: 0.875rem;                         /* 14px - Base/default text size */
  --font-size-md: 0.95rem;                            /* 15.2px - Medium text */
  --font-size-lg: 1rem;                               /* 16px - Large text */
  --font-size-xl: 1.125rem;                           /* 18px - Extra large text */
  
  /* Direct variable definitions for nested properties */
  --transition-base: 200ms;                           /* Base transition duration */
  --borders-standard: 1px solid var(--border-light);  /* Standard border style */
  --border-radius-xs: 4px;                            /* Extra small border radius */
  --effects-transform-hover: scale(1.05);             /* Standard hover transform effect */
}

html {
  height: 100%;
  scroll-behavior: smooth;
  overscroll-behavior-x: none;
} 
body {
  overscroll-behavior-x: none;
}

/* Global scrollbar styling */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 4px;
  transition: var(--transition-base);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

/* ------- NAVIGATION ------- */
.navContainer {
  background-color: var(--background-header);
  height:9vh; /* other 81vh is for right panel*/
  align-self: flex-start;
  width:100%;
}

.littleLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  border: 2px solid;
  border-radius: 10px;
  width: 80px;
  padding-left: 10px;
  padding-top: 10px;
  clear: left;
}

.littleLogo img {
  width: 44px;
  margin-right: 16px;
}

.littleBrand {
  display: inline-block;
  font-size:32px;
  font-weight: bold;
  color: var(--text-white);
}

.navButton {
  font-size:24px;
  font-weight: bold;
  float:right;
  text-align:center;
  background-color: var(--background-white);
  border: 2px solid;
  border-radius: 16px;
  margin-left: 20px;
  margin-right: 25px;
  margin-top: 13px;
  padding-left: 20px;
  padding-right:20px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.navButton:hover{
  opacity:0.8;
  cursor:pointer;
}
/* ------- NAVIGATION END ------- */


/* ------- PROCESS ------- */
input[type="file"] {
  display: none;
}

.section {
  margin: auto;
  text-align: center;
  overflow-x: auto;
  align-items: center;
}

.below-process {
  margin-bottom: auto;
  height: calc(100% - 380px);
}

.processContainer {
  height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: hidden;
}

.leftContainer {
  height: 100%;
  overflow: hidden;
  /* Properties from the panel-related definition */
  transition: width 300ms ease-in-out;
  flex: 1;
  min-width: 0;
  width: calc(100% - var(--panel-width, 0px));
}

.centerContainer {
  height: 100%;
  width: calc(100% - 600px);
  overflow: hidden;
}

.processStart {
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  color: var(--text-secondary);
}

.bloxTop{
  margin: auto;
  background-color: var(--panel-background-color);
  width: 150px;
  height: 150px;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.bloxImageTitle {
  display: block;
  text-overflow: ellipsis;
  font-weight: 500;
  max-width: 150px;
  white-space: nowrap;
  max-height: 1.2em;
  overflow: hidden;
}

.bloxImage {
  margin: auto;
  width: 120px;
}

.popover-menu-item {
  min-width: 300px;
}

.bloxTop:hover {
  transform: translateY(2px);
  cursor: grab;
}

.bloxTop.noDrag:hover{
  cursor: auto;
}

.bloxTop.selected {
  border: 2px solid rgba(var(--highlight-cyan-rgb), 0.7);
  box-shadow: 1px 2px 3px 0px rgba(var(--black-rgb), 0.1);
}

.bloxTop.selected:hover {
  transform: translateY(2px);
}

.bloxTop.selected .bloxImage,
.bloxTop.selected .blox-library-image {
  transform: none;
}

.bloxBottom {
  margin: auto;
  position: relative;
  background-color: var(--panel-background-color);
  width: 150px;
  height: 150px;
  border-radius: 10px;
  cursor: context-menu;
}

.bloxMenuIcon {
  position: absolute;
  background-color: var(--border-lighter);
  top: 0px;
  right: 0px;
  padding: 1px 8px 3px 8px;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  line-height: 8px;
}

.bloxMenuIcon .bp5-icon {
  cursor: pointer;
  transition: transform 0.2s;
  font-size: 16px;
}

.bloxMenuIcon .bp5-icon:hover {
  transform: scale(1.2);
}

.bloxExport {
  margin: 0px auto 15px auto;
  position: relative;
  text-align: center;
}
/* ------- PROCESS END ------- */

/* ------- SVG ------- */
.bloxSVG {
  position: absolute;
  width: 120px;
  bottom: 10px;
  left: 15px;
  outline: none;
  border: none;
}

.thumbnailSVG {
  width: 46px;
}

.bloxSVGPreview {
  width:240px;
}
/* ------- SVG END ------- */


/* ------- PLUS ------- */
.plusButton {
  width: 10%;
}

.plusButton:hover {
  opacity:0.8;
}

.betweenBloxesContainer {
  display: flex;
  align-items: center;
  margin: 10px;
}

.betweenBloxesPlus {
  margin: auto;
  width: 60px;
  height: 60px;
}

.betweenBloxesArrowContainer {
  height: 150px;
  display: flex;
  align-items: center;
}

.betweenAddContainer {
  align-items: center;
}

.hidden {
  display: none;
  height: 30px;
}

.betweenAddContainer:hover .show-on-hover {
  display: block;
}

/* ------- PLUS END ------- */

/*  -------LAYOUT ------- */
.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
}
/* ------- LAYOUT END ------- */

/* ------- BOTTOM PANEL ------- */

.library-callout {
  margin: auto;
  width: 700px !important;
}

.error-callout {
  margin: auto;
  width: 700px !important;
}

.editor-footer {
  background-color: var(--panel-background-color);
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  flex-shrink: 0;
  position: relative;
}

 .blox-library-container {
  overflow-x: auto;
  height: 100%
}

.blox-library-tabs {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
}

.blox-library-tabs.open {
  padding: 10px 10px 0px 10px;
}

.library-button {
  position: absolute;
  margin-left: 10px;
}

.library-blox-button {
  position: absolute;
  margin-left: 10px;
}

.library-content {
  height: 0px;
  transition: height .2s;
}

.library-content.open{
  height: 135px;
  transition: height .2s;
}

.library-content.closed{
  height: 0px;
  overflow: hidden;
  transition: height .2s;
}

/* LIBRARY */
.blox-library {
  background-color: var(--background-white);
  height: 100px;
  width: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 2px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.blox-library:hover {
  cursor: grab;
  box-shadow: var(--shadow-sm);
}

.library-label {
  width: calc(100% - 10px);
  position: absolute;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: small;
}

.library-label.top {
  width: calc(100% - 20px);
  left: 3px;
  top: 3px;
}

.library-label.bottom {
  bottom: 3px;
  width: 100%;
  text-align: center;
}

.library-image-icon-button {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.blox-library-image {
  margin: auto auto 5px auto;
  width: 80%;
}

.module-library-image {
  margin: auto;
  width: 60% !important;
}

/* LIBRARY END */

/* ------- BOTTOM PANEL END ------- */

/* ------- RIGHT PANEL ------- */
.right-panel {
  position: relative;
  transition: width 300ms ease-in-out;
  width: 300px;
  height: 100%;
  border-left: 3px solid var(--border-light);
  overflow: hidden;
  background-color: var(--panel-background-color);
  flex-shrink: 0;
}

/* Panel state when collapsed */
.right-panel--closed {
  width: 0px;
  border-radius: 8px 0 0 8px;
}

/* Panel content container */
.right-panel-content {
  position: relative;
  width: calc(300px - 4px);
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Hide content when panel is closed */
.right-panel--closed .right-panel-content {
  pointer-events: none;
}

/* Left container expanded state */
.leftContainer--expanded {
  --panel-width: 0px;
}

/* Left container collapsed state */
.leftContainer:not(.leftContainer--expanded) {
  --panel-width: 300px;
}

/* Prevent interactions during transitions */
.leftContainer--transitioning {
  pointer-events: none;
}

/* Material-item styles */

.material-item__info-hover {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.material-item__wrapper:hover {
  border-radius: 2px;
  background-color: #b3b3b34a;
}

.material-item__wrapper:hover .material-item__info-hover {
  opacity: 1;
}

.rightPanelTitle {
  font-size: large;
  text-align: center;
  width: 100%;
  margin: auto;
}

.rightPanelToolName {
  text-align: center;
  width: 100%;
  font-weight: bold;
  margin: auto;
}

.rightPanelImage {
  width: 50%;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  margin-top: 8px;
}

.rightPanelCommentsContainer {
  height: 200px !important;
}

.rightPanelComments {
  height: 183px !important;
}

.inputMenu {
  min-width: 0px !important;
}

.inputMenuPopover {
  margin-top: 0px !important;
}

.colorPreview {
  width: 30px;
  height: 30px;
  margin: 3px;
  border: var(--borders-standard);
  float: left;
  transition: var(--transition-base);
}

.colorPreview:hover {
  cursor: pointer;
  border-color: var(--border-light);
  transform: var(--effects-transform-hover);
  border-radius: var(--border-radius-xs);
}

textarea {
  resize: none;
}

.bp5-icon-lab-test svg {
  color: var(--text-secondary);
}

.bp5-tag-input {
  padding-top: 1px !important;
}

.righPanelLabel {
  position: relative;
}

.removeButton {
  position: absolute;
  right:3px;
  top:-2px;
  z-index: 1000;
}

.blox-tab-group {
  display: flex;
}

.blox-tab-group .simulate-tab {
  transition: var(--transition-base);
}

.blox-tab-group .simulate-tab-hidden {
  flex-grow: 0 !important;
  opacity: 0;
  visibility: hidden;
  min-width: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

/* Text Styles */

.warning-text {
  font-size: small;
  font-weight: 400;
  color: red;
}


/* ------- SEARCH ------- */

.searchContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.searchBarContainer {
  width: 100%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 8px;

}

.searchBarText {
  padding: 3px;
  width: 75%;
  border: var(--borders-standard);
  border-radius: var(--border-radius-xs);
  text-align: center;
  font-size: var(--font-size-base);
}

/* Landing Page */

.profile-description {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: calc(100% - 20px);
    background: var(--background-white);
    border-radius: 4px;
    flex: none;
    flex-grow: 1;
}

/* Dialog stuff */

.jump-to-button {
    margin: 5px 10px 0px auto;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;  /* Smooth transition for opacity change */
  }

  .jump-to-button:hover {
      opacity: 1;  /* Full opacity on hover */
  }

/* Responsive Button Styles */
@media (max-width: 1200px) {
  .responsive-button .bp5-button-text {
    display: none !important;
  }
  .responsive-button {
    min-width: 32px !important;
    padding: 0 5px !important;
  }
}