@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus,
input[type='button']:focus {
  outline: none !important;
}

.bp5-control input:focus ~ .bp5-control-indicator {
  outline: none !important;
}

div:focus {
  outline: none;
}

img {
  outline: none !important;
  border: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.landingLogo {
  width: 50px;
}

@keyframes traceLine {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 64px 64px;
  }
}

@keyframes revtraceLine {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -64px -64px;
  }
}

@keyframes tw-spin {
  to {
      transform: rotate(360deg);
  }
}
:root .tw-animate-slowSpin {
  animation: tw-spin 3s linear infinite;
}

:root {
  --gradient-size: 4px;
  --gradient-color: #ffffff;
}

.revDotTrace-line {
  background-image: radial-gradient(var(--gradient-color) var(--gradient-size), #DEE2FB var(--gradient-size));
  background-size: 32px 32px;
  animation: revDotTrace 8s infinite linear;
}

.trace-line {
  background-image: linear-gradient(90deg, transparent, transparent 30px, #8E4CA8 30px, #8E4CA8 32px, transparent 32px),
                    linear-gradient(transparent, transparent 30px, #8E4CA8 30px, #8E4CA8 32px, transparent 32px);
  background-size: 64px 64px;
  animation: traceLine 8s infinite linear;
}

.revTrace-line {
  background-image: linear-gradient(90deg, transparent, transparent 30px, #8E4CA8 30px, #8E4CA8 32px, transparent 32px),
                    linear-gradient(transparent, transparent 30px, #8E4CA8 30px, #8E4CA8 32px, transparent 32px);
  background-size: 64px 64px;
  animation: traceLine 4s infinite reverse linear;
}

.featureBox {
  padding: 60px 0px;
  text-align: center;
  border-bottom: 1px solid #606f7e;
}

.featureBox ul {
  padding: 0px;
  margin: 0px;
}

.featureBox li {
  display: inline-block;
  margin: 12px 12px;
  background: #606f7e;
  border-radius: 5px;
  width: min(28vw, 190px);
  height: min(25vw, 172px);
  padding: 20px;
  vertical-align: middle;
  font-size: min(2.5vw, 20px);
  color: white;
}
.featureBox li:hover {
  /* offset-x | offset-y | blur-radius | color */
  box-shadow: 0px 0px 20px #606f7ebb;
}

.featureBox h2 {
  margin: 0px;
  padding: 0px;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: min(5vw, 32px);
  line-height: min(4.2vw, 39px);
  text-align: center;
  color: #000000;
  margin-bottom: 22px;
}
a,
button {
  transition: 0.5s;
}
button:hover {
  opacity: 0.7;
}
.team-listing {
  text-align: center;
  padding: 60px 0px;
}

.team-listing h2 {
  margin: 0px;
  padding: 0px;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: min(6vw, 40px);
  line-height: min(6.2vw, 42px);
  text-align: center;
  color: #000000;
  margin-bottom: 22px;
}

.team-box {
  display: flex;
  vertical-align: top;
  justify-content: center;
  width: 320px;
  flex-direction: column;
  align-items: center;
}

.team-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 50px;
}

.team-box img {
  width: 150px !important;
  position: relative;
  z-index: 10000;
}

.team-wrap .description {
  background: #e2e6fd;
  border-radius: 4px;
  width: 95%;
  height: 300px;
  margin-top: -70px;
}

.btn-fabknow {
  background: white url('grad_hat_icon.png') no-repeat;
  width: 100%;
  padding: 6px 10px;
  border-radius: 4px;
  color: black;
  background-size: 21px;
  background-position: 5px 4px;
  padding-left: 30px;
  border: 1px solid black;
  cursor: pointer;
  margin-left: 10px;
}

.btn-fabknow:hover {
  background: #f0f0f0 url('grad_hat_icon.png') no-repeat;
  background-size: 21px;
  background-position: 5px 4px;
  padding-left: 30px;
  border: 1px solid black;
  cursor: pointer;
}

.search-box {
  display: flex;
  /* width: 65vw; */
  justify-content: space-between;
}

.search-box input[type='text'] {
  background: url('search_icon.png') no-repeat;
  border-radius: 4px;
  border: 1px solid #000000;
  padding: 6px 15px 6px 26px;
  background-position: 8px 7px;
  background-size: 14px;
  width: min(60vw, 600px);
  margin: auto;
  max-width: 100%;
}